.SdgLogo {
  background: transparent;
  width: 100%;
}
.SdgLogo img {
  object-fit: contain;
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.SdgLogo-square {
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 100%;
}

.SdgLogo-square img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

