//btns
.btn--nude() {
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

// list
.resetList() {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Layout
.mainHeaderContainer() {
  padding: 8px 0 8px 8px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.mainTitle() {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #273941;
}

.center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Modal
.Modalfooter() {
  display: flex;
}

.ModalBtnFooter() {
  flex-basis: 50%;
}
