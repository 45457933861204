/* 
** antDesign is adding rest of body styles
** and setting box model -> border-box on star selector
*/

body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}